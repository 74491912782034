<template>
  <div>
    <!-- where the modal begins  -->
    <!-- id="modal-prevent-closing" é o que linka com o b-button, linha 191 Cliente.vue   -->
    <!-- title="Cadastro de Cliente", o que aparece no botão dentro do modal  -->
    <!-- hide-footer, esconde os botões de ok e cancel quem vem no modal by default  -->
    <b-modal
      :id="clientModal.id"
      ref="my-modal"
      :title="clientModal.title"
      :content="clientModal.content"
      hide-footer
      no-close-on-backdrop
    >
      <br>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>

            <b-col md="12">
              <b-badge variant="primary">Dados do Cliente
              </b-badge>
              <br>
              <br>
              <b-form-group>
                <!-- CNPJ -->
                <validation-provider
                  #default="{ errors }"
                  rules="required|digits:14"
                  name="CNPJ"
                >
                  <b-form-input
                    v-model="itemId_cnpj"
                    :state="errors.length > 0 ? false:null"
                    placeholder="CNPJ"
                    :readonly="readonly"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Codigo-->
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Cod"
                >
                  <b-form-input
                    v-model="itemCod"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Codigo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  Name -->
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Nome"
                >
                  <b-form-input
                    v-model="itemName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Must be a valid email -->
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="E-mail"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="itemEmail"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  Telephone-->
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Telefone"
                >
                  <b-form-input
                    v-model="itemTelephone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Telefone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--Tax One-->
            <b-col md="12">
              <br>
              <b-badge variant="primary"> Tax One
              </b-badge>
              <br>
              <br>
              <b-form-group>
                <!--Tr_user-->
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Tr_user"
                >
                  <b-form-input
                    v-model="itemTr_user"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Tr_user"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Tr_passwd -->

            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Tr_passwd"
                >
                  <b-form-input
                    id="password"
                    v-model="itemTr_passwd"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Tr_passwd"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Tipo de Integração -->

            <b-col md="12">
              <br>
              <b-badge variant="primary"> Tipo de Integração
              </b-badge>
              <br>
              <br>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Arquivo"
                >
                  <b-form-select
                    v-model="selected"
                    :options="options"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Buttons -->
            <b-col class="demo-inline-spacing">

              <!-- Testar conexão button -->
              <b-button
                id="testConection"
                variant="primary"
                type="submit"
                @click.prevent=""
              >
                Testar Conexão
              </b-button>

              <!-- Cadastrar empresa button -->
              <b-button
                :id="registerButton.id"
                :content="registerButton.content"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ registerButton.content }}
              </b-button>

            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormSelect, BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BBadge, BModal,
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, min, digits, length,
} from '@validations'

export default {
  name: 'ModalEmpresa',
  components: {
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BBadge,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      readonly: false,
      clientModal: {
        id: 'modal-prevent-closing',
        title: 'Cadastro de Cliente',
        content: '',
      },
      registerButton: {
        id: 'register-button',
        content: 'Cadastrar Cliente',
      },
      selected: null,
      options: [
        { value: 'null', text: 'Selecione o tipo de integração' },
        { value: 'a', text: 'Arquivo' },
        { value: 'b', text: 'Banco de Dados' },
        { value: 'c', text: 'Integrador Fiscal' }],

      // connecting with the v-model(template), it corrected an error in the console and idk why.
      itemId_cnpj: null,
      itemCod: null,
      itemName: null,
      itemEmail: null,
      itemTelephone: null,
      itemTr_url: null,
      itemTr_user: null,
      itemAirflow_url: null,
      itemAirflow_user: null,
      itemTr_passwd: null,
      itemAirflow_passwd: null,
      required,
      confirmed,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
    }
  },
  // "validationForm" tipo de Form que deixa vermelho se os campos não estão preenchidos corretamente
  // e se todos estiverem preenchidos ao clicar no botão aciona o metodo addItem
  methods: {
    validationForm() {
      this.readonly = false
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.clientModal.title === 'Cadastro de Cliente') {
            this.addItem()
          } else {
            this.updateItem()
          }
        }
      })
    },

    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    async addItem() {
      try {
        // Getting values for POST
        const idCnpj = this.itemId_cnpj
        const cod = this.itemCod
        const name = this.itemName
        const Email = this.itemEmail
        const telephone = this.itemTelephone
        const trUser = this.itemTr_user
        // End getting values for post

        const res = await axios.post('/companyuser/', {
          id_cnpj: idCnpj,
          cod,
          name,
          email: Email,
          telephone,
          tr_url: '',
          tr_user: trUser,
          airflow_url: '',
          airflow_user: '',
          tr_passwd: '',
          airflow_passwd: '',

        })// const res = resposta que vem do axios.
        // atualiza a tabela btable
        this.$parent.btable_items_001 = [...this.$parent.btable_items_001, res.data]

        this.toast('b-toaster-top-right', true, 'success', `CNPJ ${res.data.id_cnpj} cadastrada com sucesso`, 'Cadastro Empresa')

        // const responseFiltered = []
        // res.data.forEach(item => {
        //   responseFiltered.push({
        //     value: item.id_cnpj,
        //     text: `${item.id_cnpj} - ${item.name}`,
        //   })
        // })
        // this.$refs.selectClient.options = responseFiltered

        // deixa o campos em branco após salvo.
        this.itemId_cnpj = ''
        this.itemCod = ''
        this.itemName = ''
        this.itemEmail = ''
        this.itemTelephone = ''
        this.itemTr_url = ''
        this.itemTr_user = ''
        this.itemAirflow_url = ''
        this.itemAirflow_user = ''
        this.itemTr_passwd = ''
        this.itemAirflow_passwd = ''
        this.$parent.totalRows = this.$parent.btable_items_001.length // pagination
        this.$parent.currentPage = 1
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        // foi colocado aqui pois não é correto adicionar 2 eventos no onclick, então chamamos um método dentro do outro, toast
        // dentro do addClick
        this.hideModal() // esconde o modal se cadastrado com sucesso.
        console.log(this.hideModal)
      } catch (error) {
        if (error.response) {
          if (error.response.status === 500) {
            this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
          } else {
            this.toast('b-toaster-top-right', true, 'warning', `CNPJ ${this.itemId_cnpj} já existe`, 'Cadastro Empresa')
          }
        } else {
          console.log(error)
        }
      }
    },
    async updateItem() {
      try {
        const res = await axios.patch('/companyuser/', {
          id_cnpj: this.itemId_cnpj,
          cod: this.itemCod,
          name: this.itemName,
          email: this.itemEmail,
          telephone: this.itemTelephone,
          tr_url: '',
          tr_user: this.itemTr_user,
          airflow_url: '',
          airflow_user: '',
          tr_passwd: this.itemTr_passwd,
          airflow_passwd: '',

        })// const res = resposta que vem do axios.

        // PARA CARREGAR TABELA
        // this.$parent.$options.methods.createTable()

        this.toast('b-toaster-top-right', true, 'success', `CNPJ ${res.data.id_cnpj} Atualizado com sucesso`, 'Cadastro Empresa')
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        // foi colocado aqui pois não é correto adicionar 2 eventos no onclick, então chamamos um método dentro do outro, toast
        // dentro do addClick
        this.hideModal() // esconde o modal se cadastrado com sucesso.
      } catch (error) {
        if (error.response.status === 500) {
          this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        } else {
          this.toast('b-toaster-top-right', true, 'warning', `CNPJ ${this.itemId_cnpj} já existe`, 'Cadastro Empresa')
        }
      }
    },

  },
}
</script>
